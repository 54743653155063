
import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export interface State {
  id: number;
  name: string;
  code: string;
  initials: string;
}


export abstract class StateData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<string[]>;
  abstract get(id: number): Observable<State>;
  abstract update(registeData: State): Observable<State>;
  abstract create(registeData: State): Observable<State>;
  abstract delete(id: number): Observable<boolean>;
}
