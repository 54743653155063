import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatApi } from '../api/chat.api';

@Injectable()
export class ChatAPIService {

    constructor(private api: ChatApi) { }

    public checkState(session: string): Observable<any> {
        return this.api.checkState(session);
    }

    public addQueue(session: string): Observable<any> {
        return this.api.addQueue(session);
    }

    public cancelQueue(session: string): Observable<any> {
        return this.api.cancelQueue(session);
    }

    public join(channelId: string, session: string): Observable<any> {
        return this.api.join(channelId, session);
    }

    public sendMessage(channelId: string, payload: any): Observable<any> {
        return this.api.sendMessage(channelId, payload);
    }

    public endConversation(channelId: string): Observable<any> {
        return this.api.endConversation(channelId);
    }

    public sendIsTyping(channelId: string, session: string, state: boolean): Observable<any> {
        return this.api.sendIsTyping(channelId, session, state);
    }
}
