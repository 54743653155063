import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RolesApi } from '../api/roles.api';

@Injectable()
export class RolesService {

  constructor(private api: RolesApi) {
  }

  list(): Observable<any[]> {
    return this.api.list();
  }
}
