
<h1 id="title" class="title">Atualizar senha</h1>
<p class="sub-title">Por favor, defina uma nova senha</p>

<nb-alert *ngIf="showMessages?.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">Ocorreu um erro ao resetar o password tente novamente</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages?.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">Senha atualizada com sucesso</li>
  </ul>
</nb-alert>

<div [formGroup]="resetPasswordForm" aria-labelledby="title">

  <div class="form-control-group">
    <label for="input-password">Password:</label>
    <input  nbInput
            autofocus
            fullWidth
            id="input-password"
            formControlName="password"
            type="password"
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
            class="first"
            placeholder="Password">
    <ngx-validation-message label="Password" [showMinLength]="password?.hasError('minlength') && password.touched"
      [showMaxLength]="password?.hasError('maxlength') && password.touched"
      [showRequired]="password?.errors?.required && password.touched"
      [minLength]="minLength"
      [maxLength]="maxLength"></ngx-validation-message>
  </div>

  <div class="form-control-group">
    <label for="input-re-password">Confirmar Password:</label>
    <input  nbInput
            fullWidth
            id="input-re-password"
            formControlName="confirmPassword"
            type="password"
            [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
            placeholder="Password">
    <ngx-validation-message label="Confirm Password"
      [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
      [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
      [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched"
      [minLength]="minLength"
      [maxLength]="maxLength"></ngx-validation-message>
    <p  class="error-message caption status-danger" *ngIf="password.value != confirmPassword.value && confirmPassword.touched">
      A senha e a confirmação da senha não correspondem!
    </p>
  </div>

  <button nbButton status="success" fullWidth [disabled]="submitted || !resetPasswordForm.valid || password.value != confirmPassword.value"
    [class.btn-pulse]="submitted"
    (click)="resetPass()">
    Atualizar
  </button>
</div>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Volte ao login</a></p>
  <!-- <p><a class="text-link" routerLink="../register">Register</a></p> -->
</section>
