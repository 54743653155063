
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { GridData } from '../common/gridData';
import { UnitMeasurement } from './unit-measurement';
import { Address } from './address';
import { Item } from './item';
import { Provider } from './provider';

export interface RequisitionItem {
  id: number,
  deliveryDeadline: number,
  requisitionId: string,
  unitMeasurement: UnitMeasurement,
  differentAddress: Boolean,
  address: Address,
  observation: String,
  items: Item
  providers: Array<Provider>,
  requisition: Request,
  ncm: string,
  status: string,
  numRequisitionItem: string,
  quantity: number,
  itemId: number,
  unitMeasurementId: string,
  materialSource: string,
  referenePrice: number,
}


export abstract class RequisitionItemData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<GridData<RequisitionItem>>;
  abstract get(id: number): Observable<RequisitionItem>;
  abstract update(registeData: RequisitionItem): Observable<RequisitionItem>;
  abstract create(registeData: RequisitionItem): Observable<RequisitionItem>;
  abstract delete(id: number): Observable<boolean>;
}
