import { NgModule, ModuleWithProviders } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpRequest } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  NbAuthJWTInterceptor,
  NbAuthModule,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
  NbTokenLocalStorage,
  NbAuthService,
  NbPasswordAuthStrategy,
  NbAuthOAuth2JWTToken,
} from "@nebular/auth";
import { AuthInterceptor } from "./auth.interceptor";
import { AuthGuard } from "./auth.guard";
import { AdminGuard } from "./admin.guard";
import { AuthPipe } from "./auth.pipe";
import { RoleProvider } from "./role.provider";
import { NbRoleProvider, NbSecurityModule } from "@nebular/security";
import { NgBrazil } from "ng-brazil";

import {
  NgxLoginComponent,
  NgxAuthComponent,
  NgxAuthBlockComponent,
  NgxLogoutComponent,
  NgxRegisterComponent,
  NgxRequestPasswordComponent,
  NgxResetPasswordComponent,
} from "./components";

import {
  NbAlertModule,
  NbCardModule,
  NbIconModule,
  NbLayoutModule,
  NbCheckboxModule,
  NbInputModule,
  NbButtonModule,
  NbSelectModule,
} from "@nebular/theme";
import { AuthRoutingModule } from "./auth-routing.module";
import { ComponentsModule } from "../@components/components.module";
import { authOptions } from "./auth.settings";
import { authSettings } from "./access.settings";
import { RegisterService } from "../@core/backend/portal/services/register.service";
import { RegisterApi } from "../@core/backend/portal/api/register.api";
import { PaymentConditionsService } from "../@core/backend/portal/services/payment-condition.service";
import { FormPaymentService } from "../@core/backend/portal/services/form-payment.service";
import { FormPaymentApi } from "../@core/backend/portal/api/form-payment.api";
import { TextMaskModule } from "angular2-text-mask";
import { StorageService } from "../@core/backend/portal/services/storage.service";

const GUARDS = [AuthGuard, AdminGuard];
const PIPES = [AuthPipe];
const COMPONENTS = [
  NgxLoginComponent,
  NgxAuthComponent,
  NgxLogoutComponent,
  NgxRegisterComponent,
  NgxRequestPasswordComponent,
  NgxResetPasswordComponent,
  NgxAuthBlockComponent,
];

const NB_MODULES = [
  NbIconModule,
  NbLayoutModule,
  NbCardModule,
  NbAlertModule,
  NbCheckboxModule,
  NbInputModule,
  NbButtonModule,
  NbSelectModule,
];

export function filterInterceptorRequest(req: HttpRequest<any>): boolean {
  return [
    "/auth/login",
    "/auth/sign-up",
    "/auth/request-pass",
    "/auth/refresh-token",
  ].some((url) => req.url.includes(url));
}

@NgModule({
  declarations: [...PIPES, ...COMPONENTS],
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    ComponentsModule,
    TextMaskModule,
    NgBrazil,
    ...NB_MODULES,
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: "email",
          token: {
            class: NbAuthOAuth2JWTToken,
            key: "token",
          },
        }),
      ],
    }),
  ],
  exports: [...PIPES],
  providers: [
    NbSecurityModule.forRoot({
      accessControl: authSettings,
    }).providers,
    {
      provide: NbRoleProvider,
      useClass: RoleProvider,
    },
    {
      provide: NbTokenLocalStorage,
      useClass: NbTokenLocalStorage,
    },
    RegisterService,
    RegisterApi,
    PaymentConditionsService,
    FormPaymentService,
    FormPaymentApi,
  ],
})
export class AuthModule {
  constructor(
    authService: NbAuthService,
    authStrategy: NbPasswordAuthStrategy
  ) {
    authStrategy.setOptions({
      name: "email",
      baseEndpoint: localStorage.getItem("api_url"),
      token: {
        class: NbAuthOAuth2JWTToken,
        key: "token",
      },
      login: {
        endpoint: "/auth/login",
        method: "post",
      },
      register: {
        endpoint: "/auth/sign-up",
        method: "post",
      },
      logout: {
        endpoint: "/auth/sign-out",
        method: "post",
      },
      requestPass: {
        endpoint: "/auth/request-pass",
        method: "post",
      },
      resetPass: {
        endpoint: "/auth/reset-pass",
        method: "post",
      },
      refreshToken: {
        endpoint: "/auth/refresh-token",
        method: "post",
      },
    });
  }
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
          useValue: filterInterceptorRequest,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: NbAuthJWTInterceptor,
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ...GUARDS,
      ],
    };
  }
}
