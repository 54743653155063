import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-finalize-quote',
  templateUrl: 'dialog-finalize-quote.component.html',
  styleUrls: ['dialog-finalize-quote.component.scss'],
})
export class DialogFinalizeQuoteComponent {

  mensagem: string = 'Você realmente deseja finalizar a cotação? Os itens não respondidos serão declinados automaticamente.';

  constructor(protected ref: NbDialogRef<DialogFinalizeQuoteComponent>) {
    
  }

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.ref.close(true);
  }
}



