import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrdersApi } from '../api/orders.api';
import { OrderData, Order } from '../../../interfaces/portal/orders';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { RequestResponse } from '../../../interfaces/portal/company';

@Injectable()
export class OrdersService implements OrderData {

  constructor(private api: OrdersApi) { }

  get gridDataSource(): DataSource {
    return this.api.ordersDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Order>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Order> {
    return this.api.get(id);
  }

  create(order: any): Observable<RequestResponse> {
    return this.api.add(order);
  }

  update(order: any): Observable<RequestResponse> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  declinOrder(id: number, observation: string): Observable<RequestResponse> {
    return this.api.declinOrder(id, observation);
  }

  confirmOrder(id: number, observation: string): Observable<RequestResponse> {
    return this.api.confirmOrder(id, observation);
  }

  eme4Api(): Observable<RequestResponse> {
    return this.api.eme4Api();
  }
}

