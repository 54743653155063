import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { UnitMeasurementData, UnitMeasurement } from '../../../interfaces/portal/unit-measurement';
import { UnitMeasurementApi } from '../api/unit-measurement.api';

@Injectable()
export class UnitMeasurementService implements UnitMeasurementData {

  constructor(private api: UnitMeasurementApi) { }

  get gridDataSource(): DataSource {
    return this.api.unitMeasurementDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<UnitMeasurement>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<UnitMeasurement> {
    return this.api.get(id);
  }

  create(unitmeasurement: any): Observable<UnitMeasurement> {
    return this.api.add(unitmeasurement);
  }

  update(unitmeasurement: any): Observable<UnitMeasurement> {
    return this.api.update(unitmeasurement);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
