import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../../common/api/http.service';
import { State } from '../../../interfaces/common/state';

@Injectable()
export class StateApi {
  private readonly apiController: string = 'states';

  constructor(private api: HttpService) {}

  get StateDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<State>> {
    const params = new HttpParams()
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`);

    return this.api.get(this.apiController, { params });
  }

  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }
}
