import {Component, Input} from '@angular/core';

@Component({
  selector: 'splash-loader',
  styleUrls: ['./splash-loader.component.scss'],
  template: `
    <div *ngIf="show" class="splash-loading-container">
        <div class="splash-loading-panel">
            <span>{{message}}</span>
            <div class="loader"></div>            
        </div>        
    </div>
  `  
})
export class SplashLoaderComponent {
  @Input()
  message = "";  

  @Input()
  show = false;  
}
