

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderTypesApi } from '../api/order-types.api';
import { OrderTypeData } from '../../../interfaces/portal/order-type';

@Injectable()
export class OrderTypesService implements OrderTypeData {

  constructor(private api: OrderTypesApi) { }

  list(): Observable<string[]> {
    return this.api.list();
  }
}
