import { Injectable } from '@angular/core';  
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';  
import { Observable, of } from 'rxjs';  
import { tap } from 'rxjs/operators';  
import { HttpCacheService } from './http-cache.service';  
  
  
@Injectable()  
export class CacheInterceptor implements HttpInterceptor {  
  
  constructor(private cacheService: HttpCacheService) { }  
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
  

    const authReq = req.clone({
      // Prevent caching in IE, in particular IE11.
      // See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);

    // const httpRequest = req.clone({
    //   headers: new HttpHeaders({
    //     'Cache-Control': ' no-cache'
    //   })
    // });

    // return next.handle(httpRequest);


    // repassar solicitações não armazenáveis ​​em cache e invalidar o cache
   // if(req.method !== 'GET') {  
     // console.log(`Invalidating cache: ${req.method} ${req.url}`);  


  // this.cacheService.invalidateCache();  
  //    return next.handle(req);  
   // }  
  



   
    // tentativa de recuperar uma resposta em cache 
    // const cachedResponse: HttpResponse<any> = this.cacheService.get(req.url);  
  
    // // resposta em cache eturn 
    // if (cachedResponse) {  
    //   console.log(`Returning a cached response: ${cachedResponse.url}`);  
    //   console.log(cachedResponse);  
    //   return of(cachedResponse);  
    // }      
  
    // enviar solicitação ao servidor e adicionar resposta ao cache 
    // return next.handle(req)  
    //   .pipe(  
    //     tap(event => {  
    //       if (event instanceof HttpResponse) {  
    //         console.log(`Adding item to cache: ${req.url}`);  
    //         this.cacheService.put(req.url, event);  
    //       }  
    //     })  
    //   );  
  
  }  
}  