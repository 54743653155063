import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { RequestResponse } from '../../../interfaces/portal/company';
import { Payment, PaymentData } from '../../../interfaces/portal/payment';
import { PaymentApi } from '../api/payment.api';

@Injectable()
export class PaymentService implements PaymentData {

  constructor(private api: PaymentApi) { }

  get gridDataSource(): DataSource {
    return this.api.ordersDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Payment>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Payment> {
    return this.api.get(id);
  }

  create(order: any): Observable<Payment> {
    return this.api.add(order);
  }

  update(order: any): Observable<Payment> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

}