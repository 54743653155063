import { User, UserData } from './../../../@core/interfaces/common/users';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Item, ItemData } from '../../../@core/interfaces/portal/item';

@Component({
  selector: 'ngx-dialog-confirm',
  templateUrl: 'user-search.component.html',
  styleUrls: ['user-search.component.scss'],
})
export class UserSearchComponent {

  mensagem: string = 'Favor selecionar um item';
  protected readonly unsubscribe$ = new Subject<void>();
  items: User[];

  constructor(
    protected ref: NbDialogRef<UserSearchComponent>,
    private itemService: UserData,
  ) {
    this.source = this.itemService.gridDataSource;
  }

  source: DataSource;

  settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: false,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
      firstName: {
        title: 'Nome',
        type: 'string',
      },
      lastName: {
        title: 'Sobrenome',
        type: 'string',
      },
      login: {
        title: 'Nome de Usuário',
        type: 'string',
      },
      email: {
        title: 'Email',
        type: 'string',
      }
    },
  };

  cancel() {
    this.ref.close(0);
  }

  submit(id) {
    this.ref.close(id);
  }

  onEdit($event: any) {
    this.ref.close($event.data);
  }

  confirmar($event: any) {
    this.ref.close($event.data);
  }
}





