import { Component, OnDestroy, OnInit } from "@angular/core";
import { AnalyticsService } from "./@core/utils";
import { InitUserService } from "./@theme/services/init-user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { StorageService } from "./@core/backend/portal/services/storage.service";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private analytics: AnalyticsService,
    private storageService: StorageService,
    private initUserService: InitUserService
  ) {
    this.initUser();
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }

  initUser() {
    this.storageService.getAPIURL().subscribe((x) => {
      this.storageService.url = `${this.storageService.url == undefined ? "" : this.storageService.url}${x}`;
      localStorage.setItem('api_url', this.storageService.url);
    });
    this.initUserService
      .initCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
