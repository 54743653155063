import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { FormPaymentApi } from '../api/form-payment.api';
import { FormPayment, FormPaymentData } from '../../../interfaces/portal/form-payment';

@Injectable()
export class FormPaymentService implements FormPaymentData {

  constructor(private api: FormPaymentApi) { }

  get gridDataSource(): DataSource {
    return this.api.formPaymentDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<FormPayment>> {
    return this.api.list(pageNumber, pageSize);
  }
}
