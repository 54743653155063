

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class OrdersProfitApi {

  private readonly apiController: string = 'orders-profit';

  constructor(private api: HttpService) {}

  getProfitInfo(): Observable<any> {
    return this.api.get(`${this.apiController}/summary`);
  }

  getProfitYear(): Observable<any> {
    return this.api.get(this.apiController);
  }

  getProfitMonth(): Observable<any> {
    return this.api.get(`${this.apiController}/short`);
  }
}
