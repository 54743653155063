import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Order } from './orders';
import { Provider } from '@angular/core';

export interface Payment {
  id: number;
  groupCompany: string;
  codIntegration: string;
  numberNF: string;
  numberSAP: string;
  order: Order;
  numOrder: string;
  invoiceAmount: number;
  paymentAmount: number,
  paymentDate: Date;
  dueDate: Date,
  status: string;
  provider: Provider;
}

export abstract class PaymentData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<Payment>>;
  abstract get(id: number): Observable<Payment>;
  abstract update(payment: Payment): Observable<Payment>;
  abstract create(payment: Payment): Observable<Payment>;
  abstract delete(id: number): Observable<boolean>;
}

