import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { RequisitionItemApi } from '../api/requisition-item';
import { RequisitionItem, RequisitionItemData } from '../../../interfaces/portal/requisition-item';

@Injectable()
export class RequisitionItemService implements RequisitionItemData {

  constructor(private api: RequisitionItemApi) { }

  get gridDataSource(): DataSource {
    return this.api.requestDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<RequisitionItem>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<RequisitionItem> {
    return this.api.get(id);
  }

  create(order: any): Observable<RequisitionItem> {
    return this.api.add(order);
  }

  update(order: any): Observable<RequisitionItem> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
