import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Settings } from './settings';
import { GridData } from './gridData';

export interface User {
  id: number;
  userRoles: Array<any>;
  firstName: string;
  lastName: string;
  email: string;
  name?: string;
  groupCod?: string;
  login: string;
  picture: string;
  settings: Settings;
  lastAccess?: Date
}



export abstract class UserData {
  abstract get gridDataSource(): DataSource;
  abstract getCurrentUser(): Observable<User>;
  abstract list(pageNumber: number, pageSize: number): Observable<User[]>;
  abstract listar(pageNumber: number, pageSize: number, logado: boolean): Observable<User[]> ;
  abstract get(id: number): Observable<User>;
  abstract update(user: User): Observable<User>;
  abstract updateCurrent(user: User): Observable<User>;
  abstract create(user: User): Observable<User>;
  abstract delete(id: number): Observable<boolean>;
  abstract resetPassword(user: User): Observable<boolean>;
  abstract downLoggedList(): Observable<any>;
}