import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatService } from '../chat.service';

@Component({
    selector: 'ngx-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnDestroy {

    get queues() {
        return this.chatService.chatListItems;
    }

    constructor(private chatService: ChatService) {}

    ngOnInit(): void { }

    ngOnDestroy(): void { }
}
