

import { NgModule } from '@angular/core';
import { NbButtonModule, NbCardModule } from '@nebular/theme';

import { ThemeModule } from '../../@theme/theme.module';
import { MiscellaneousRoutingModule } from './miscellaneous-routing.module';
import { MiscellaneousComponent } from './miscellaneous.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { ItemSearchComponent } from './item-search/item-search.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { CompanySearchComponent } from './company-search/company-search.component';
import { DialogTemplateComponent } from './dialog-template/dialog-template.component';
import { DialogCancelComponent } from './dialog-cancel/dialog-cancel.component';
import { DialogDeclineQuoteComponent } from './dialog-decline-quote/dialog-decline-quote.component';
import { DialogFinalizeQuoteComponent } from './dialog-finalize-quote/dialog-finalize-quote.component';
import { DialogConfirmOrderComponent } from './dialog-confirm-order/dialog-confirm-order.component';
import { DialogDeclineOrderComponent } from './dialog-decline-order/dialog-decline-order.component';
import { DialogParticipateQuoteComponent } from './dialog-participate-quote/dialog-participate-quote.component';
import { DialogDeclineItemComponent } from './dialog-decline-item/dialog-decline-item.component';
import { DialogReopenItemComponent } from './dialog-reopen-item/dialog-reopen-item.component';

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    MiscellaneousRoutingModule,
    Ng2SmartTableModule,
  ],
  declarations: [
    MiscellaneousComponent,
    NotFoundComponent,
    DialogConfirmComponent,
    ItemSearchComponent,
    ProviderSearchComponent,
    UserSearchComponent,
    CompanySearchComponent,
    DialogTemplateComponent,
    DialogCancelComponent,
    DialogDeclineQuoteComponent,
    DialogFinalizeQuoteComponent,
    DialogConfirmOrderComponent,
    DialogDeclineOrderComponent,
    DialogParticipateQuoteComponent,
    DialogDeclineItemComponent,
    DialogReopenItemComponent,
  ],
  exports:[UserSearchComponent]  
})
export class MiscellaneousModule { }
