import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { PaymentConditionData, PaymentCondition } from '../../../interfaces/portal/payment-condition';
import { PaymentConditionApi } from '../api/payment-condition.api';

@Injectable()
export class PaymentConditionsService implements PaymentConditionData {

  constructor(private api: PaymentConditionApi) { }

  get gridDataSource(): DataSource {
    return this.api.paymentconditionsDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<PaymentCondition>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<PaymentCondition> {
    return this.api.get(id);
  }

  create(paymentcondition: any): Observable<PaymentCondition> {
    return this.api.add(paymentcondition);
  }

  update(paymentcondition: any): Observable<PaymentCondition> {
    return this.api.update(paymentcondition);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
