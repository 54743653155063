import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ChatService } from '../chat.service';
import { EndConversationDialogComponent } from '../end-conversation-dialog/end-conversation-dialog.component';

@Component({
    selector: 'ngx-chat-list-item',
    templateUrl: './chat-list-item.component.html',
    styleUrls: ['./chat-list-item.component.scss']
})
export class ChatListItemComponent implements OnInit, OnDestroy {

    get sessionId() {
        return this.chatService.sessionId;
    }

    constructor(private dialogService: NbDialogService, private chatService: ChatService) { }

    @Input('item') queue!: any;

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    join(channelId: any, event: any): void {
        event.preventDefault();
        this.chatService.join(channelId);
    }

    endConversation(queue: any, event: any) {
        event.preventDefault();

        this.dialogService.open(EndConversationDialogComponent, {
            context: {
                message: `Deseja encerrar a conversa com '${queue.providerName}?'`,
            }, closeOnBackdropClick: false
        })
            .onClose.subscribe(stausConfirm => {
                if (stausConfirm === true) {
                    this.chatService.endConversation(queue.channelId);
                }
            });
    }
}
