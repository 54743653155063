<nb-card>
    <nb-card-header>{{ mensagem }}</nb-card-header>
    <nb-card-body>
        <ng2-smart-table
            class="request-smart-table"
            [settings]="settings"
            [source]="source"
            (edit)="onEdit($event)">
        </ng2-smart-table>
    </nb-card-body>
    <nb-card-footer>
      <button class="cancel" nbButton status="danger" (click)="cancel()">Cancelar</button>
    </nb-card-footer>
  </nb-card>
