import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Item, ItemData } from '../../../interfaces/portal/item';
import { ItemsApi } from '../api/items.api';

@Injectable()
export class ItemsService implements ItemData {

  constructor(private api: ItemsApi) { }

  get gridDataSource(): DataSource {
    return this.api.itemsDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Item>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Item> {
    return this.api.get(id);
  }

  create(order: any): Observable<Item> {
    return this.api.add(order);
  }

  update(order: any): Observable<Item> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  listByName(FilterByName: string, pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Item>> {
    return this.api.listByName(FilterByName, pageNumber, pageSize);
  }
}
