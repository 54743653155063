
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { GridData } from '../common/gridData';

export interface Item {
  id: number;
  name: string;
  codMaterial: string;
  codManufacturer: string;
  ncm: string;
}


export abstract class ItemData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<GridData<Item>>;
  abstract listByName(FilterByName: string, pageNumber: number, pageSize: number): Observable<GridData<Item>>;
  abstract get(id: number): Observable<Item>;
  abstract update(registeData: Item): Observable<Item>;
  abstract create(registeData: Item): Observable<Item>;
  abstract delete(id: number): Observable<boolean>;
}
