import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { flatMap } from "rxjs/operators";

interface RuntimeConfig {
  PARAM_API_URL: string;
}

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private http: HttpClient) {}
  url: string;
  public getAPIURL(): Observable<string> {
    return this.http.get("assets/json/runtime.json").pipe(
      flatMap((x: Object) => {
        const config = x as RuntimeConfig;
        return config.PARAM_API_URL;
      })
    );
  }
}
