import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export interface FormPayment {
  id: number;
  description: string;
  code: string;
}

export abstract class FormPaymentData {
  abstract get gridDataSource(): DataSource;
}
