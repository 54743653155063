import { Company, CompanyData } from './../../../@core/interfaces/portal/company';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'ngx-dialog-confirm',
  templateUrl: 'company-search.component.html',
  styleUrls: ['company-search.component.scss'],
})
export class CompanySearchComponent {

  mensagem: string = 'Favor selecionar um centro';
  protected readonly unsubscribe$ = new Subject<void>();
  items: Company[];

  constructor(
    protected ref: NbDialogRef<CompanySearchComponent>, 
    private itemService: CompanyData,
  ) {
    this.source = this.itemService.gridDataSource;
  }

  source: DataSource;

  settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: false,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
        codCompany: {
          title: 'Código',
          type: 'string',
          width: '10%',
          filter: false,
        },
        name: {
          title: 'Nome',
          type: 'string',
          width: '40%',
        },
        cnpj: {
          title: 'CNPJ',
          type: 'string',
          width: '20%',
          sort: false,
        },
        active: {
          title: 'Ativo',
          type: 'boolean',
          width: '10%',
        },
    },
  };

  cancel() {
    this.ref.close(0);
  }

  submit(id) {
    this.ref.close(id);
  }

  onEdit($event: any) {
    this.ref.close($event.data);
  }
}




