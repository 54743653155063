

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserActivityData, UserActive } from '../../../interfaces/portal/user-activity';
import { UserActivityApi } from '../api/user-activity.api';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class UserActivityService implements UserActivityData {

  // constructor(private api: UserActivityApi) {
  //   super();
  // }

  constructor(private api: UserActivityApi) { }

  get gridDataSource(): DataSource {
    return this.api.userActivictDataSource;
  }

  getUserActivityData(period: string): Observable<UserActive[]> {
    return this.api.list(period)
      .pipe(map(data => data.map(item => {
        return {
          date: item.label,
          pagesVisitCount: item.pagesVisit,
          deltaUp: item.trend >= 0,
          newVisits: Math.floor(item.trend),
        };
      })));
  }

  // getTotal() {
  //   return this.api.getTotal();
  // }

  getTotal(): Observable<any> {
    return this.api.getTotal();
  }

  getStatistics(): Observable<any> {
    return this.api.getStatistics();
  }

  // getStatistics() {
  //   return this.api.getStatistics();
  // }


  listarUsuarios(pageNumber: number, pageSize: number, logado: boolean): Observable<any[]> {
    return this.api.listarUsuarios(pageNumber, pageSize, logado);
  }

  downLoggedList(): Observable<any> {
    return this.api.downLoggedList();
  }
  
}
