<nb-card>
    <nb-card-header>
      Selecione um usuário
    </nb-card-header>
    <nb-card-body>
      <ng2-smart-table
      class="request-smart-table"
      [settings]="settings"
      [source]="source"
      (edit)="onEdit($event)">
    </ng2-smart-table>
    </nb-card-body>
    <nb-card-footer>
      <button type="submit" (click)="cancel()" class="btn btn-primary">Cancelar</button>
    </nb-card-footer>
  </nb-card>
  
