

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderStatusesApi } from '../api/order-statuses.api';
import { OrderStatusData } from '../../../interfaces/portal/order-status';

@Injectable()
export class OrderStatusesService implements OrderStatusData {

  constructor(private api: OrderStatusesApi) { }

  list(): Observable<string[]> {
    return this.api.list();
  }
}
