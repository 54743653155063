
      <div class="warning">
          <span class="caption status-danger"
             *ngIf="showMinLength"> O comprimento mínimo para o campo {{ label }} é de {{ minLength }} caracteres  
          </span>
          <span class="caption status-danger"
             *ngIf="showMaxLength"> O comprimento máximo para o campo {{ label }} é de {{ maxLength }} caracteres 
          </span>
          <span class="caption status-danger" *ngIf="showPattern"> Incorreto {{ label }} </span>
          <span class="caption status-danger" *ngIf="showRequired"> {{ label }} é obrigatório</span>
          <span class="caption status-danger" *ngIf="showMin">O valor mínimo para {{ label }} é {{ min }}</span>
          <span class="caption status-danger" *ngIf="showMax">O valor máximo para o campo {{ label }} é {{ max }}</span>
      </div>
  