import { RequestResponse } from './../../../interfaces/portal/company';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { QuoteProviderItem, QuoteProviderItemData } from '../../../interfaces/portal/quote-provider-item';
import { QuoteProviderItemApi } from '../api/quote-provider-item.api';

@Injectable()
export class QuoteProviderItemService implements QuoteProviderItemData {

  constructor(private api: QuoteProviderItemApi) { }

  get gridDataSource(): DataSource {
    return this.api.requestDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<QuoteProviderItem>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<QuoteProviderItem> {
    return this.api.get(id);
  }

  create(order: any): Observable<RequestResponse> {
    return this.api.add(order);
  }

  update(order: any): Observable<RequestResponse> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  decline(id: number): Observable<RequestResponse> {
    return this.api.decline(id);
  }

  reopen(id: number): Observable<RequestResponse> {
    return this.api.reopen(id);
  }

  getBackAndNextIdItemQuote(idQuote: string, idQuoteItem: string, fieldSort: string, fieldDirection: string): Observable<any> {
    return this.api.getBackAndNextIdItemQuote(idQuote, idQuoteItem, fieldSort, fieldDirection);
  }

}
