<nb-card>
    <nb-card-header>Atenção</nb-card-header>
    <nb-card-body>
        {{ message }}
    </nb-card-body>
    <nb-card-footer style="display:flex;justify-content: flex-end">
        <button class="cancel" nbButton status="danger" (click)="cancel($event)">Cancelar</button>
        &nbsp;
        <button nbButton status="success" (click)="submit($event)">Confirmar</button>
    </nb-card-footer>
</nb-card>