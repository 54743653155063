<li>
    <div class="queue-status" [ngClass]="queue.hasChatStarted ? 'queue-green' : 'queue-orange'"></div>
    <div class="provider-info">
        <div class="provider-name">
            <div>
                <span>{{ queue.providerName }}</span>
            </div>
        </div>
        <div class="buyer-name">
            <span>{{ queue.buyerName }}</span>
        </div>
    </div>
    <div class="go-chat" *ngIf="!queue.hasChatStarted || (queue.hasChatStarted && queue.buyerConnectionId == sessionId)">
        <div class="chat-button-position" (click)="join(queue.channelId, $event)">
            <nb-badge *ngIf="queue.newMessage > 0" [text]="queue.newMessage" status="success" position="top right" class="badge"></nb-badge>
            <button nbButton shape="round" size="small" status="success" class="chat-button">
                <nb-icon icon="message-circle-outline"></nb-icon>
            </button>
        </div>
        <button nbButton shape="round" size="small" status="warning" class="chat-button" style="margin-left:10px;" [disabled]="!queue.hasChatStarted" (click)="endConversation(queue, $event)">
            <nb-icon icon="close-outline"></nb-icon>
        </button>
    </div>
</li>