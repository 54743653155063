import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { Order } from '../../../interfaces/portal/orders';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../../common/api/http.service';
import { RequestResponse } from '../../../interfaces/portal/company';

@Injectable()
export class OrdersApi {
    private readonly apiController: string = 'orders';

    constructor(private api: HttpService) { }

    get ordersDataSource(): DataSource {
        return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
    }

    list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Order>> {
        const params = new HttpParams()
            .set('pageNumber', `${pageNumber}`)
            .set('pageSize', `${pageSize}`);

        return this.api.get(this.apiController, { params });
    }

    get(id: number): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`);
    }

    delete(id: number): Observable<any> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    add(item: any): Observable<any> {
        return this.api.post(this.apiController, item);
    }

    update(item: any): Observable<any> {
        return this.api.put(`${this.apiController}/${item.id}`, item);
    }

    declinOrder(id: number, observation: string): Observable<any> {
        return this.api.post(`${this.apiController}/declinOrder`, { id: id, observation: observation });
    }

    confirmOrder(id: number, observation: string): Observable<any> {
        return this.api.post(`${this.apiController}/confirmOrder`, { id: id, observation: observation });
    }

    eme4Api(): Observable<RequestResponse> {
        return this.api.post(`eme4/Orders`, null);
    }
}
