import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../../common/api/http.service';
import { QuoteItem } from '../../../interfaces/portal/quote-item';
import { QuoteProviderItem } from '../../../interfaces/portal/quote-provider-item';

@Injectable()
export class QuoteProviderItemApi {
  private readonly apiController: string = 'quoteProviderItems';

  constructor(private api: HttpService) {}

  get requestDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<QuoteProviderItem>> {
    const params = new HttpParams()
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`);

    return this.api.get(this.apiController, { params });
  }

  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  delete(id: number): Observable<any> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  add(item: any): Observable<any> {
    return this.api.post(this.apiController, item);
  }

  update(item: any): Observable<any> {
    return this.api.put(`${this.apiController}/${item.id}`, item);
  }

  decline(id: number): Observable<any> {
    return this.api.post(`${this.apiController}/decline/${id}`, id);
  }

  reopen(id: number): Observable<any> {
    return this.api.post(`${this.apiController}/reopen/${id}`, id);
  }

  getBackAndNextIdItemQuote(idQuote: string, idQuoteItem: string, fieldSort: string, fieldDirection: string): Observable<any> {
    const params = new HttpParams()
      .set('idQuote', `${idQuote}`)
      .set('idQuoteItem', `${idQuoteItem}`)
      .set('fieldSort', `${fieldSort}`)
      .set('fieldDirection', `${fieldDirection}`);

    return this.api.get(`${this.apiController}/getBackAndNextIdItemQuote`, { params });
  }
  
}


