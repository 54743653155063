import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export interface UnitMeasurement {
  id: number;
  name: string;
  initials: string;
  active: boolean;
}

export abstract class UnitMeasurementData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<UnitMeasurement>>;
  abstract get(id: number): Observable<UnitMeasurement>;
  abstract update(register: UnitMeasurement): Observable<UnitMeasurement>;
  abstract create(register: UnitMeasurement): Observable<UnitMeasurement>;
  abstract delete(id: number): Observable<boolean>;
}



