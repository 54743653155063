import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-confirm',
  templateUrl: 'dialog-cancel.component.html',
  styleUrls: ['dialog-cancel.component.scss'],
})
export class DialogCancelComponent {

  mensagem: string = 'Você está prestes cancelar o registro, deseja continuar?';

  constructor(protected ref: NbDialogRef<DialogCancelComponent>) {

  }

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.ref.close(true);
  }
}
