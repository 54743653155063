import { ProviderApi } from '../api/provider.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Quote, QuoteData } from '../../../interfaces/portal/quote';
import { QuoteApi } from '../api/quote.api';
import { QuoteProvider, QuoteProviderData, QuoteProviderResponse } from '../../../interfaces/portal/quote-provider';
import { QuoteProviderApi } from '../api/quote-provider.api';
import { RequestResponse } from '../../../interfaces/portal/company';

@Injectable()
export class QuoteProviderService implements QuoteProviderData {

  constructor(private api: QuoteProviderApi) { }
  

  get gridDataSource(): DataSource {
    return this.api.requestDataSource;
  }

  getDataSourcePaging(pageNumber: number = 1): DataSource {
    return this.api.getDataSourcePaging(pageNumber);
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<QuoteProvider>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<QuoteProvider> {
    return this.api.get(id);
  }

  create(order: any): Observable<RequestResponse> {
    return this.api.add(order);
  }

  update(order: any): Observable<RequestResponse> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  participateQuote(id: number, idStatus: number): Observable<RequestResponse> {
    return this.api.participateQuote(id, idStatus);
  }

  declineQuote(id: number): Observable<RequestResponse> {
    return this.api.declineQuote(id);
  }

  finishQuote(id: number): Observable<RequestResponse> {
    return this.api.finishQuote(id);
  }

  downloadItens(id: number, fieldSort: string, fieldDirection: string): Observable<QuoteProviderResponse> {
    return this.api.downloadItens(id, fieldSort, fieldDirection);
  }

  uploadItens(file: any): Observable<any> {
    return this.api.uploadItens(file);
  }

  editByResponseProvider(id: number, paymentConditionId: number, formPayment: string): Observable<RequestResponse> {
    return this.api.editByResponseProvider(id, paymentConditionId, formPayment);
  }

  getStatusQuote(id: number): Observable<string> {
    return this.api.getStatusQuote(id);
  }


}
