import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'ngx-end-conversation-dialog',
    templateUrl: './end-conversation-dialog.component.html',
    styleUrls: ['./end-conversation-dialog.component.scss']
})
export class EndConversationDialogComponent {
    
    public message !: string;

    constructor(protected ref: NbDialogRef<EndConversationDialogComponent>) { }

    cancel(event: any): void {
        event.preventDefault();
        this.ref.close(false);
    }

    submit(event: any): void {
        event.preventDefault();
        this.ref.close(true);
    }
}
