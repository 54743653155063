import { Observable } from 'rxjs';
import { GridData } from './gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export interface State {
  id: number;
  name: string;
  code: string;
  initials: string;
}

export abstract class StateData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<State>>;
  abstract get(id: number): Observable<State>;
}
