import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { OrderItem, OrderItemData } from '../../../interfaces/portal/orders-item';
import { OrdersItemApi } from '../api/orders-item.api';

@Injectable()
export class OrdersItemService implements OrderItemData {

  constructor(private api: OrdersItemApi) { }

  get gridDataSource(): DataSource {
    return this.api.requestDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<OrderItem>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<OrderItem> {
    return this.api.get(id);
  }
}
