import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { CountyData, County } from '../../../interfaces/common/county';
import { CountyApi } from '../api/county.api';

@Injectable()
export class CountyService implements CountyData {

  constructor(private api: CountyApi) { }

  get gridDataSource(): DataSource {
    return this.api.countyDataSource;
  }

  list(filterByInitials: string, pageNumber: number = 1, pageSize: number = 10): Observable<GridData<County>> {
    return this.api.list(filterByInitials, pageNumber, pageSize);
  }

  get(id: number): Observable<County> {
    return this.api.get(id);
  }
}
