import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Address } from './address';

export interface Company {
  id: number;
  name: string;
  codCompany: string;
  address: Address;
  cnpj: string;
  active: boolean;
}

export interface RequestResponse { 
  idRegister: number;
  status: string;
  mensagem: string;
  objeto: any;
}

export abstract class CompanyData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<Company>>;
  abstract get(id: number): Observable<Company>;
  abstract update(company: Company): Observable<RequestResponse>;
  abstract create(company: Company): Observable<RequestResponse>;
  abstract delete(id: number): Observable<boolean>;
}
