

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class OrderTypesApi {

  private readonly apiController: string = 'order-types';

  constructor(private api: HttpService) {}

  list(): Observable<any> {
    return this.api.get(this.apiController);
  }
}
