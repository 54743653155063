import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../../common/api/http.service';
import { County } from '../../../interfaces/common/county';

@Injectable()
export class CountyApi {
  private readonly apiController: string = 'countyS';

  constructor(private api: HttpService) {}

  get countyDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  list(filterByStateId: string, pageNumber: number = 1, pageSize: number = 10): Observable<GridData<County>> {
    const params = new HttpParams()
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`)
      .set('filterByStateId', `${filterByStateId}`);

    return this.api.get(this.apiController, { params });
  }

  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }
}
