

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { User } from '../../../interfaces/common/users';

@Injectable()
export class UserActivityApi {

  private readonly apiController: string = 'user-activity';

  constructor(private api: HttpService) {}

  get userActivictDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  list(period: string): Observable<any> {
    const params = new HttpParams()
      .set('date', `${period}`);

    return this.api.get(this.apiController, { params });
  }

  getTotal(): Observable<any> {
    return this.api.get(`${this.apiController}/get-total`);
  }

  getStatistics(): Observable<any> {
    return this.api.get(`${this.apiController}/get-statistics`);
  }

  listarUsuarios(pageNumber: number, pageSize: number, logado: boolean): Observable<User[]> {
    const params = new HttpParams()
    .set('FilterByLogged', `${logado}`)
    .set('pageNumber', `${pageNumber}`)
    .set('pageSize', `${pageSize}`);

    return this.api.get(`${this.apiController}/get-users`, { params });
  }

  downLoggedList(): Observable<any> {
    return this.api.get(`${this.apiController}/get-users-download`);
  }
}
