
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonMockModule } from '../common/common-mock.module';
import { CountryOrderData } from '../../interfaces/portal/country-order';
import { OrdersProfitChartData } from '../../interfaces/portal/orders-profit-chart';
import { ProfitBarAnimationChartData } from '../../interfaces/portal/profit-bar-animation-chart';
import { StatsBarData } from '../../interfaces/portal/stats-bar';
import { StatsProgressBarData } from '../../interfaces/portal/stats-progress-bar';
import { TrafficListData } from '../../interfaces/portal/traffic-list';
import { UserActivityData } from '../../interfaces/portal/user-activity';
import { EarningData } from '../../interfaces/portal/earning';
import { VisitorsAnalyticsData } from '../../interfaces/portal/visitors-analytics';
import { CountryOrderService } from './country-order.service';
import { OrdersProfitChartService } from './orders-profit-chart.service';
import { ProfitBarAnimationChartService } from './profit-bar-animation-chart.service';
import { StatsBarService } from './stats-bar.service';
import { StatsProgressBarService } from './stats-progress-bar.service';
import { TrafficListService } from './traffic-list.service';

import { EarningService } from './earning.service';
import { OrdersChartService } from './orders-chart.service';
import { ProfitChartService } from './profit-chart.service';
import { VisitorsAnalyticsService } from './visitors-analytics.service';

const SERVICES = [
  { provide: CountryOrderData, useClass: CountryOrderService },
  { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
  { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
  { provide: StatsBarData, useClass: StatsBarService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
  { provide: TrafficListData, useClass: TrafficListService },

  { provide: EarningData, useClass: EarningService },
  { provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService },
];

const INTERNAL_SERVICES = [
  OrdersChartService,
  ProfitChartService,
];

@NgModule({
  imports: [CommonModule, CommonMockModule],
  exports: [CommonMockModule],
})
export class PortalMockModule {
  static forRoot(): ModuleWithProviders<PortalMockModule> {
    return {
      ngModule: PortalMockModule,
      providers: [
        ...INTERNAL_SERVICES,
        ...SERVICES,
      ],
    };
  }
}
