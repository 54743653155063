import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { StateData, State } from '../../../interfaces/common/state';
import { StateApi } from '../api/state.api';

@Injectable()
export class StateService implements StateData {

  constructor(private api: StateApi) { }

  get gridDataSource(): DataSource {
    return this.api.StateDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<State>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<State> {
    return this.api.get(id);
  }
}
