

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserStore } from '../../../@core/stores/user.store';
import { SettingsData } from '../../../@core/interfaces/common/settings';
import { User } from '../../../@core/interfaces/common/users';
import { NbAccessChecker } from '@nebular/security';
import { ChatService } from '../../../pages/chat/chat.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly: boolean = false;
    user: User;

    themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
        {
            value: 'cosmic',
            name: 'Cosmic',
        },
        {
            value: 'corporate',
            name: 'Corporate',
        },
    ];

    currentTheme = 'default';

    userMenu = this.getMenuItems();

    get iconState() {
        return this.chatService.bShowHideChatListIcon;
    }

    constructor(private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private userStore: UserStore,
        private settingsService: SettingsData,
        private layoutService: LayoutService,        
        private breakpointService: NbMediaBreakpointsService,
        private chatService: ChatService) {
    }

    getMenuItems() {
        const userLink = this.user ? '/pages/users/current/' : '';
        return [
            { title: 'Perfil', link: userLink, queryParams: { profile: true } },
            { title: 'Alterar Senha', link: '/auth/reset-password' },
            { title: 'Sair', link: '/auth/logout' },
        ];
    }

    ngOnInit() {
        this.currentTheme = this.themeService.currentTheme;

        this.userStore.onUserStateChange()
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe((user: User) => {
                this.user = user;
                this.userMenu = this.getMenuItems();
            });

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$),
            )
            .subscribe(themeName => this.currentTheme = themeName);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.userStore.setSetting(themeName);
        this.settingsService.updateCurrent(this.userStore.getUser().settings)
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }
}
