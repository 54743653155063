import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Coin, CoinData } from '../../../interfaces/portal/coin';
import { CoinApi } from '../api/coin.api';

@Injectable()
export class CoinService implements CoinData {

  constructor(private api: CoinApi) { }

  get gridDataSource(): DataSource {
    return this.api.coinDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Coin>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Coin> {
    return this.api.get(id);
  }

  create(order: any): Observable<Coin> {
    return this.api.add(order);
  }

  update(order: any): Observable<Coin> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
