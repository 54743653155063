import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../../common/api/http.service';
import { QuoteItem } from '../../../interfaces/portal/quote-item';
import { QuoteProviderItem } from '../../../interfaces/portal/quote-provider-item';
import { OrderItem } from '../../../interfaces/portal/orders-item';

@Injectable()
export class OrdersItemApi {
  private readonly apiController: string = 'orderItems';

  constructor(private api: HttpService) {}

  get requestDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<OrderItem>> {
    const params = new HttpParams()
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`);

    return this.api.get(this.apiController, { params });
  }

  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }
 
}


