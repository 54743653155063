import { Observable } from 'rxjs';
import { GridData } from './gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { State } from './state';

export interface County {
  id: number;
  name: string;
  state: State;
}

export abstract class CountyData {
  abstract get gridDataSource(): DataSource;
  abstract list(filterByStateId: string, pageNumber: number, pageSize: number): Observable<GridData<County>>;
  abstract get(id: number): Observable<County>;
}
