import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private httpClient: HttpClient, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  obterLogradouro(cep: string): any {
    return this.httpClient.get(`https://viacep.com.br/ws/${cep}/json`);
  }
}
