import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-confirm',
  templateUrl: 'dialog-template.component.html',
  styleUrls: ['dialog-template.component.scss'],
})
export class DialogTemplateComponent {

  mensagem: string = 'Você está prestes a enviar a cotação ao mercado, deseja continuar?';

  constructor(protected ref: NbDialogRef<DialogTemplateComponent>) {

  }

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.ref.close(true);
  }
}




