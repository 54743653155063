

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class TrafficAggregatedApi {

  private readonly apiController: string = 'traffic-aggregated';

  constructor(private api: HttpService) {}

  getList(period: string): Observable<any> {
    const params = new HttpParams()
      .set('filter', `${period}`);

    return this.api.get(this.apiController, { params });
  }
}
