import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { CompanyData, Company, RequestResponse } from '../../../interfaces/portal/company';
import { CompanyApi } from '../api/company.api';


@Injectable()
export class CompanyService implements CompanyData {

  constructor(private api: CompanyApi) { }

  get gridDataSource(): DataSource {
    return this.api.companyDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Company>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Company> {
    return this.api.get(id);
  }

  create(company: any): Observable<RequestResponse> {
    return this.api.add(company);
  }

  update(company: any): Observable<RequestResponse> {
    return this.api.update(company);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
