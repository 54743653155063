import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../../common/api/http.service';
import { QuoteProvider } from '../../../interfaces/portal/quote-provider';
import { strict } from 'assert';

@Injectable()
export class QuoteProviderApi {
  private readonly apiController: string = 'quoteProviders';

  constructor(private api: HttpService) {}

  get requestDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  getDataSourcePaging(pageNumber: number = 1): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}?pageNumber=${pageNumber}`);
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<QuoteProvider>> {
    const params = new HttpParams()
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`);

    return this.api.get(this.apiController, { params });
  }

  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  delete(id: number): Observable<any> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  add(item: any): Observable<any> {
    return this.api.post(this.apiController, item);
  }

  update(item: any): Observable<any> {
    return this.api.put(`${this.apiController}/${item.id}`, item);
  }

  participateQuote(id: number, idStatus: number): Observable<any> {
    var item =  {id: id, idStatus: idStatus,};
    return this.api.post(`${this.apiController}/participateQuote/${id}`, item);
  }

  declineQuote(id: number): Observable<any> {
    var item =  {id: id};
    return this.api.post(`${this.apiController}/declineQuote/${id}`, item);
  }

  finishQuote(id: number): Observable<any> {
    var item =  {id: id};
    return this.api.post(`${this.apiController}/finishQuote/${id}`, item);
  }

  downloadItens(id: number, fieldSort: string, fieldDirection: string): Observable<any> {
    return this.api.get(`${this.apiController}/downloadItens/${id}/${fieldSort}/${fieldDirection}`);
  }

  uploadItens(file: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    // const options = new RequestOptions({ headers: headers });

    const options = {
      headers: headers,
    };

    return this.api.post(`${this.apiController}/uploadItens/`, file);
  }


  editByResponseProvider(id: number, paymentConditionId: number, formPayment: string): Observable<any> {
    var item =  {id: id, paymentConditionId: paymentConditionId, formPayment: formPayment};
    return this.api.put(`${this.apiController}/editByResponseProvider?id=${id}`, item);
  }


  getStatusQuote(id: number): Observable<string> {
    return this.api.get(`${this.apiController}/getStatusQuote/${id}`);
  }

}
