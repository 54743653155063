

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CountryOrderData } from '../../../interfaces/portal/country-order';
import { OrdersAggregatedApi } from '../api/orders-aggregated.api';
import { OrderTypesApi } from '../api/order-types.api';

@Injectable()
export class CountryOrderService extends CountryOrderData {

  constructor(private apiAggregated: OrdersAggregatedApi, private apiOrderTypes: OrderTypesApi) {
    super();
  }

  getCountriesCategories(): Observable<string[]> {
    return this.apiOrderTypes.list();
  }

  getCountriesCategoriesData(country: string): Observable<number[]> {
    return this.apiAggregated.getCountriesCategoriesData(country)
      .pipe(map(data => data.map(item => item.count)));
  }
}
