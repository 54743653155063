import { ProviderApi } from './../api/provider.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Request, RequestData } from '../../../interfaces/portal/request';
import { RequestApi } from '../api/request.api';

@Injectable()
export class RequestService implements RequestData {

  constructor(private api: RequestApi) { }

  get gridDataSource(): DataSource {
    return this.api.requestDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Request>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Request> {
    return this.api.get(id);
  }

  create(order: any): Observable<Request> {
    return this.api.add(order);
  }

  update(order: any): Observable<Request> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  getNextNumRequisition(): Observable<string> {
    return this.api.getNextNumRequisition();
  }
}
