import { Company, RequestResponse } from './company';

import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { GridData } from '../common/gridData';
import { UnitMeasurement } from './unit-measurement';
import { Address } from './address';
import { Item } from './item';
import { Provider } from './provider';
import { Quote } from './quote';

export interface QuoteItem {
  id: number,
  quoteId: number,
  itemId: number,
  observation: string,
  description: string,
  deliveryDeadline: number,
  deliveryDate: Date,
  unitMeasurement: UnitMeasurement,
  quantity: number,
  unitMeasurementId: string,
  referenePrice: number,
  companyDelivery: Company,
  status: string,
  ncm: string,
  materialSource: string,
  providers: Array<Provider>,
  numQuoteItem: string,
  imported: boolean,
}

export interface QuoteItemProvider {
  id: number,
  providerId: number,
  provider: Provider,
  quoteItemId: number,
}


export abstract class QuoteItemData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<GridData<QuoteItem>>;
  abstract get(id: number): Observable<QuoteItem>;
  abstract update(registeData: QuoteItem): Observable<RequestResponse>;
  abstract create(registeData: QuoteItem): Observable<RequestResponse>;
  abstract delete(id: number): Observable<boolean>;
  abstract addProvider(registeData: any): Observable<QuoteItemProvider>;
  abstract removeProvider(idProvider: number, idQuoteItem: number): Observable<boolean>;
}
