import { Injectable } from '@angular/core';
import { Register } from '../../../interfaces/common/register';
import { RegisterApi } from '../api/register.api';

@Injectable()
export class RegisterService {

    constructor(private api: RegisterApi) { }

    save(register: Register) {
        return this.api.save(register);
    }
}
