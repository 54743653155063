import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Provider } from './provider';

export interface BankData {
  id: number;
  bankAcount: string;
  bankAcountDigit: string;
  active: boolean;
  Provider?: Provider;
}

export abstract class BankDataData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<BankData>>;
  abstract get(id: number): Observable<BankData>;
  abstract update(company: BankData): Observable<BankData>;
  abstract create(company: BankData): Observable<BankData>;
  abstract delete(id: number): Observable<boolean>;
}
