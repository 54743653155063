import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class AppLogService extends ErrorHandler {

    constructor() {
        super();
    }

    handleError(error) {        
        super.handleError(error);

        console.log("** Ng:", error);
    }
}