import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class RegisterApi {
  private readonly apiController: string = 'Eme4';

  constructor(private api: HttpService) {}

  save(item: any): Observable<any> {
    return this.api.post(`${this.apiController}/RegisterProvider`, item);
  }

}
