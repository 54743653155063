

import { Component, OnDestroy } from '@angular/core';
import { ChatService } from '../../../pages/chat/chat.service';

@Component({
    selector: 'ngx-one-column-layout',
    styleUrls: ['./one-column.layout.scss'],
    template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>
      
      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
        <ngx-chat-list *ngIf="listState"></ngx-chat-list>
        <ngx-chat-icon-provider *ngIf="chatState"></ngx-chat-icon-provider>
        <ngx-chat-popup *ngIf="chatFormState"></ngx-chat-popup>
      </nb-layout-column>
      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnDestroy {

    get listState() {
        return this.chatService.bShowHideChatList;
    }

    get chatState() {
        return this.chatService.bShowHideChatProvIcon;
    }

    get chatFormState(){
        return this.chatService.bShowHideChatForm;
    }

    constructor(private chatService: ChatService) {

    }

    ngOnDestroy() {

    }
}
