import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Company } from '../portal/company';
import { Address } from './address';

export interface Request {
  id: number;
  numRequisition: string;
  groupCompany: string;
  dateCreation: Date;
  buyerGroup: string;
  coinId: string;
  dateLimit: Date;
  paymentConditionId: string;
  observation: string;
  status: string;
  formPayment: string;
  companyId: string;
  addressBilling: Address;
  addressDelivery: Address;
}

export abstract class RequestData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<Request>>;
  abstract get(id: number): Observable<Request>;
  abstract update(request: Request): Observable<Request>;
  abstract create(request: Request): Observable<Request>;
  abstract delete(id: number): Observable<boolean>;
  abstract getNextNumRequisition(): Observable<string>;
}
