import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { BankData, BankDataData } from '../../../interfaces/portal/bankData';
import { BankDataApi } from '../api/bankData.api';


@Injectable()
export class BankDataService implements BankDataData {

  constructor(private api: BankDataApi) { }

  get gridDataSource(): DataSource {
    return this.api.companyDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<BankData>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<BankData> {
    return this.api.get(id);
  }

  create(bankData: any): Observable<BankData> {
    return this.api.add(bankData);
  }

  update(bankData: any): Observable<BankData> {
    return this.api.update(bankData);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}