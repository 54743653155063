import { ProviderApi } from './../api/provider.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Provider, ProviderData, UserProvider } from '../../../interfaces/portal/provider';
import { RequestResponse } from '../../../interfaces/portal/company';
import { EmailResponse } from '../../../interfaces/common/emailResponse';

@Injectable()
export class ProviderService implements ProviderData {

  constructor(private api: ProviderApi) { }

  get gridDataSource(): DataSource {
    return this.api.ordersDataSource;
  }


  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<Provider>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Provider> {
    return this.api.get(id);
  }

  create(order: any): Observable<RequestResponse> {
    return this.api.add(order);
  }

  update(order: any): Observable<RequestResponse> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  addUser(order: any): Observable<UserProvider> {
    return this.api.addUser(order);
  }

  removeUser(order: any): Observable<UserProvider> {
    return this.api.removeUser(order);
  }

  inviteProvider(order: any): Observable<EmailResponse> {
    return this.api.inviteProvider(order);
  }

}
