import { Observable } from "rxjs";

export abstract class ChatData {
    abstract checkState(session: string);
    abstract join(channelId: string, sessionId: string);
    abstract addQueue(session: string): Observable<string>;
    abstract cancelQueue(session: string): Observable<string>;
    abstract sendMessage(channelId: string, payload: any);
    abstract endConversation(channelId: string) : Observable<any>;
    abstract sendIsTyping(channelId: string, session: string, state: boolean);
}
