import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-decline-item',
  templateUrl: 'dialog-decline-item.component.html',
  styleUrls: ['dialog-decline-item.component.scss'],
})
export class DialogDeclineItemComponent {

  mensagem: string = 'Você realmente deseja declinar deste item?';

  constructor(protected ref: NbDialogRef<DialogDeclineItemComponent>) {
    
  }

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.ref.close(true);
  }
}
