import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export interface PaymentCondition {
  id: number;
  name: string;
  codIntegration: string;
}

export abstract class PaymentConditionData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<PaymentCondition>>;
  abstract get(id: number): Observable<PaymentCondition>;
  abstract update(paymentcondition: PaymentCondition): Observable<PaymentCondition>;
  abstract create(paymentcondition: PaymentCondition): Observable<PaymentCondition>;
  abstract delete(id: number): Observable<boolean>;
}
