<nb-card>
    <nb-card-header>Registro - Cadastro de Fornecedor</nb-card-header>
    <nb-card-body>
        <div [formGroup]="registerForm" aria-labelledby="title">
            <h6 class="grid-h">Informações gerais</h6>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="namE_ORG1" class="label">Nome / Razão Social</label>
                        <input type="text" nbInput fullWidth id="namE_ORG1" formControlName="namE_ORG1" [status]="namE_ORG1?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="Nome / Razão Social" [showRequired]="namE_ORG1?.errors?.required" [showMaxLength]="namE_ORG1?.hasError('maxlength')" [showMinLength]="namE_ORG1?.hasError('minlength')" maxLength=40 minLength=4></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="namE_ORG2" class="label">Nome Fantasia</label>
                        <input type="text" nbInput fullWidth id="namE_ORG2" formControlName="namE_ORG2" [status]="(namE_ORG2?.hasError('maxlength')) ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="Nome Fantasia" [showMaxLength]="namE_ORG2?.hasError('maxlength')" maxLength=40></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="taxnumxl" class="label">CNPJ</label>
                        <input type="text" nbInput fullWidth [textMask]="{mask: MASKS.cnpj.textMask}" id="taxnumxl" formControlName="taxnumxl" [status]="taxnumxl?.errors?.required || taxnumxl?.hasError('pattern') ? 'danger' : 'primary'" class="form-control" class="form-control" placeholder="">
                        <ngx-validation-message label="CNPJ" [showPattern]="taxnumxl?.errors" [showRequired]="taxnumxl?.errors?.required"></ngx-validation-message>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="taxnumxL_MUNIP" class="label">Inscrição Municipal</label>
                        <input type="text" nbInput fullWidth id="taxnumxL_MUNIP" formControlName="taxnumxL_MUNIP" status="primary" class="form-control" placeholder="">
                        <ngx-validation-message label="Inscrição Municipal" maxLength=40></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="taxnumxL_EST" class="label">Inscrição Estadual</label>
                        <input type="text" nbInput fullWidth id="taxnumxL_EST" formControlName="taxnumxL_EST" status="primary" class="form-control" placeholder="">
                        <ngx-validation-message label="Inscrição Estadual" maxLength=40></ngx-validation-message>
                    </div>
                </div> 
            </div>

            <h6 class="grid-h">Endereço</h6>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="posT_CODE1" class="label">CEP</label>
                        <input type="text" nbInput fullWidth id="posT_CODE1" formControlName="posT_CODE1" (focusout)="buscarEndereco($event.target.value)" [status]="posT_CODE1?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="0000000" cep [textMask]="{mask: MASKS.cep.textMask}">
                        <ngx-validation-message label="CEP" [showRequired]="posT_CODE1?.errors?.required" [showMaxLength]="posT_CODE1?.hasError('maxlength')" [showMinLength]="posT_CODE1?.hasError('minlength')" maxLength=10 minLength=8></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="street" class="label">Logradouro</label>
                        <input type="text" nbInput fullWidth id="street" formControlName="street" [status]="street?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="Endereço" [showRequired]="street?.errors?.required" [showMaxLength]="street?.hasError('maxlength')" [showMinLength]="street?.hasError('minlength')" maxLength=60 minLength=4></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="supl" class="label">Complemento</label>
                        <input type="text" nbInput fullWidth id="supl" formControlName="supl" [status]="(supl?.hasError('maxlength')) ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="Complemento" [showMaxLength]="supl?.hasError('maxlength')" maxLength=10></ngx-validation-message>
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="citY2" class="label">Bairro</label>
                        <input type="text" nbInput fullWidth id="citY2" formControlName="citY2" [status]="citY2?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="Bairro" [showRequired]="citY2?.errors?.required" [showMaxLength]="citY2?.hasError('maxlength')" [showMinLength]="citY2?.hasError('minlength')" maxLength=40 minLength=4></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="citY1" class="label">Cidade</label>
                        <input type="text" nbInput fullWidth id="citY1" formControlName="citY1" [status]="citY1?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="Cidade" [showRequired]="citY1?.errors?.required" [showMaxLength]="citY1?.hasError('maxlength')" maxLength=40></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="region" class="label">UF</label>
                        <input type="text" nbInput fullWidth id="region" formControlName="region" [status]="region?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="UF" [showRequired]="region?.errors?.required" [showMaxLength]="region?.hasError('maxlength')" [showMinLength]="region?.hasError('minlength')" maxLength=2 minLength=2></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="country" class="label">País</label>
                        <input type="text" nbInput fullWidth id="country" formControlName="country" [status]="country?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="">
                        <ngx-validation-message label="País" [showRequired]="country?.errors?.required" [showMaxLength]="country?.hasError('maxlength')" [showMinLength]="country?.hasError('minlength')" maxLength=2 minLength=2></ngx-validation-message>
                    </div>
                </div>
            </div>
            <h6 class="grid-h">Contato</h6>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="teL_NUMBER" class="label">Telefone</label>
                        <input type="text" nbInput fullWidth id="teL_NUMBER" formControlName="teL_NUMBER" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [status]="teL_NUMBER?.errors?.required ? 'danger' : 'primary'" class="form-control" placeholder="(00) 00000-0000">
                        <ngx-validation-message label="Telefone" [showRequired]="teL_NUMBER?.errors?.required" [showMaxLength]="teL_NUMBER?.hasError('maxlength')" maxLength=30></ngx-validation-message>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="smtP_ADDR" class="label">Email</label>
                        <input type="text" nbInput fullWidth id="smtP_ADDR" formControlName="smtP_ADDR" [status]="(smtP_ADDR?.errors?.required || smtP_ADDR?.hasError('pattern')) ? 'danger' : 'primary'" class="form-control" placeholder="Email">
                        <ngx-validation-message label="Email" [showRequired]="smtP_ADDR?.errors?.required" [showPattern]="smtP_ADDR?.hasError('pattern')"></ngx-validation-message>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton status="primary" [disabled]="!registerForm.valid" (click)="save()" hero>Salvar</button>
    </nb-card-footer>
</nb-card>