import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-decline-quote',
  templateUrl: 'dialog-decline-quote.component.html',
  styleUrls: ['dialog-decline-quote.component.scss'],
})
export class DialogDeclineQuoteComponent {

  mensagem: string = 'Você realmente deseja declinar da cotação? Todos itens serão declinados automaticamente.';

  constructor(protected ref: NbDialogRef<DialogDeclineQuoteComponent>) {
    
  }

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.ref.close(true);
  }
}



