import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Provider, ProviderData } from '../../../@core/interfaces/portal/provider';

@Component({
  selector: 'ngx-dialog-confirm',
  templateUrl: 'provider-search.component.html',
  styleUrls: ['provider-search.component.scss'],
})
export class ProviderSearchComponent {

  mensagem: string = 'Favor selecionar um item';
  protected readonly unsubscribe$ = new Subject<void>();
  items: Provider[];

  constructor(
    protected ref: NbDialogRef<ProviderSearchComponent>, 
    private objService: ProviderData,
  ) {
    this.source = this.objService.gridDataSource;
  }

  source: DataSource;

  settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: false,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
      name: {
        title: 'Name',
        type: 'string',
      },
      secundName: {
        title: 'Segundo Nome',
        type: 'string',
      },
      cnpjCpf: {
        title: 'Cpf/Cnpj',
        type: 'string',
        width: '10%',
        sort: false,
      },
      email: {
        title: 'E-mail',
        type: 'string',
      },
      active: {
        title: 'Ativo',
        type: 'boolean',
        width: '10%',
      },
    },
  };

  cancel() {
    this.ref.close(0);
  }

  submit(id) {
    this.ref.close(id);
  }

  onEdit($event: any) {
    this.ref.close($event.data);
  }
}




