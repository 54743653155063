import { Coin } from './coin';
import { Company, RequestResponse } from './company';
import { PaymentCondition } from './payment-condition';
import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export interface Order {
  id: number;
  numOrdem: string;
  groupCompany: string;
  dateCreation: string;
  buyerGroup: string;
  coinId: string;
  coin: Coin,
  providerId: string;
  paymentConditionId: string;
  paymentCondition: PaymentCondition,
  companyBillingId: string;
  companyBilling: Company,
  companyDeliveryId: string;
  companyDelivery: Company,
  formPayment: string;
  status: string;
  observation: string;
}

export abstract class OrderData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<Order>>;
  abstract get(id: number): Observable<Order>;
  abstract update(order: Order): Observable<RequestResponse>;
  abstract create(order: Order): Observable<RequestResponse>;
  abstract declinOrder(id: number, observation: string): Observable<RequestResponse>;
  abstract confirmOrder(id: number, observation: string): Observable<RequestResponse>;
  abstract delete(id: number): Observable<boolean>;
  abstract eme4Api(): Observable<RequestResponse>;
}
