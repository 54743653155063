import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { QuoteItemApi } from '../api/quote-item.api';
import { QuoteItem, QuoteItemData, QuoteItemProvider } from '../../../interfaces/portal/quote-item';
import { RequestResponse } from '../../../interfaces/portal/company';

@Injectable()
export class QuoteItemService implements QuoteItemData {

  constructor(private api: QuoteItemApi) { }

  get gridDataSource(): DataSource {
    return this.api.requestDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<QuoteItem>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<QuoteItem> {
    return this.api.get(id);
  }

  create(order: any): Observable<RequestResponse> {
    return this.api.add(order);
  }

  update(order: any): Observable<RequestResponse> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  addProvider(order: any): Observable<QuoteItemProvider> {
    return this.api.addProvider(order);
  }

  removeProvider(idProvider: number, idQuoteItem: number): Observable<boolean> {
    return this.api.removeProvider(idProvider, idQuoteItem);
  }
}
