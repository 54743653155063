import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_PATTERN } from '../constants';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { UtilsService } from '../../../@core/utils/utils.service';
import { RegisterService } from '../../../@core/backend/portal/services/register.service';
import { PaymentCondition, PaymentConditionData } from '../../../@core/interfaces/portal/payment-condition';
import { PaymentConditionsService } from '../../../@core/backend/portal/services/payment-condition.service';
import { GridData } from '../../../@core/interfaces/common/gridData';
import { FormPaymentService } from '../../../@core/backend/portal/services/form-payment.service';

@Component({
    selector: 'ngx-register',
    styleUrls: ['./register.component.scss'],
    templateUrl: './register.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRegisterComponent implements OnInit {

    public MASKS = MASKS;

    payments: any[];
    formPayment: any[];

    submitted = false;
    errors: string[] = [];
    messages: string[] = [];
    user: any = {};

    registerForm: FormGroup;
    constructor(
        private registerService: RegisterService,
        private fb: FormBuilder,
        private router: Router,
        public utilsService: UtilsService,
        private toastrService: NbToastrService,
        private paymentConditionsService: PaymentConditionsService,
        private formPaymentService: FormPaymentService
    ) {
    }

    get bukrs() { return this.registerForm.get('bukrs'); }
    get namE_ORG1() { return this.registerForm.get('namE_ORG1'); }
    get namE_ORG2() { return this.registerForm.get('namE_ORG2'); }
    get namE_LAST() { return this.registerForm.get('namE_LAST'); }
    get street() { return this.registerForm.get('street'); }
    get housE_NUM1() { return this.registerForm.get('housE_NUM1'); }
    get building() { return this.registerForm.get('building'); }
    get floor() { return this.registerForm.get('floor'); }
    get rooM_NO() { return this.registerForm.get('rooM_NO'); }
    get supl() { return this.registerForm.get('supl'); }
    get citY2() { return this.registerForm.get('citY2'); }
    get posT_CODE1() { return this.registerForm.get('posT_CODE1'); }
    get teL_NUMBER() { return this.registerForm.get('teL_NUMBER'); }
    get citY1() { return this.registerForm.get('citY1'); }
    get country() { return this.registerForm.get('country'); }
    get region() { return this.registerForm.get('region'); }
    get taxnumxl() { return this.registerForm.get('taxnumxl'); }
    get taxnumxL_EST() { return this.registerForm.get('taxnumxL_EST'); }
    get taxnumxL_MUNIP() { return this.registerForm.get('taxnumxL_MUNIP'); }
    get smtP_ADDR() { return this.registerForm.get('smtP_ADDR'); }
    get banks() { return this.registerForm.get('banks'); }
    get bankl() { return this.registerForm.get('bankl'); }
    get bankn() { return this.registerForm.get('bankn'); }
    get bkont() { return this.registerForm.get('bkont'); }
    get zwels() { return this.registerForm.get('zwels'); }
    get zterm() { return this.registerForm.get('zterm'); }
    get motivO_SOLIC() { return this.registerForm.get('motivO_SOLIC'); }
    get jusT_MOTIVO() { return this.registerForm.get('jusT_MOTIVO'); }
    get bkref() { return this.registerForm.get('bkref'); }
    get zgrupo() { return this.registerForm.get('zgroup'); }

    ngOnInit(): void {
        this.initForm();

        this.paymentConditionsService.list(1, 1000).subscribe(data => {
            this.payments = data.items;
        });

        this.formPaymentService.list(1, 1000).subscribe(data => {
            this.formPayment = data.items;
        });
    }

    initForm() {
        this.registerForm = this.fb.group({
            namE_ORG1: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
            namE_ORG2: this.fb.control('', [Validators.maxLength(40)]),
            //namE_LAST: this.fb.control('', [Validators.maxLength(40)]),
            street: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(60)]),
            // housE_NUM1: this.fb.control('', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]),
            // building: this.fb.control('', [Validators.maxLength(20)]),
            // floor: this.fb.control('', [Validators.maxLength(10)]),
            // rooM_NO: this.fb.control('', [Validators.maxLength(10)]),
            supl: this.fb.control('', [Validators.maxLength(10)]),
            //bkref: this.fb.control(''),
            citY2: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
            posT_CODE1: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(10)]),
            teL_NUMBER: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]),
            citY1: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
            country: this.fb.control('BR', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
            region: this.fb.control('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
            taxnumxl: this.fb.control('', [Validators.required, NgBrazilValidators.cnpj]),
            // taxnumxL_EST: this.fb.control('', [Validators.maxLength(15)]),
            // taxnumxL_MUNIP: this.fb.control('', [Validators.maxLength(15)]),
            smtP_ADDR: this.fb.control('', [
                Validators.required,
                Validators.pattern(EMAIL_PATTERN),
            ]),
            // banks: this.fb.control('BR', [Validators.required, Validators.minLength(2), Validators.maxLength(15)]),
            // bankl: this.fb.control('', [Validators.maxLength(15)]),
            // bankn: this.fb.control('', [Validators.maxLength(18)]),
            // bkont: this.fb.control('', [Validators.maxLength(2)]),
            // zwels: this.fb.control('', [Validators.required]),
            // zterm: this.fb.control('', [Validators.required]),
            // zgrupo: this.fb.control('COMPORTE', [Validators.required]),
            // motivO_SOLIC: this.fb.control('Cadastro fornecedor', [Validators.required, Validators.minLength(4), Validators.maxLength(60)]),
            // jusT_MOTIVO: this.fb.control('Portal de compras', [Validators.required, Validators.minLength(4), Validators.maxLength(60)]),
        });
    }

    save() {
        const form = { ...this.registerForm.value };

        const cepForm = String(form.posT_CODE1);
        const cep = cepForm.replace('.', '');
        form.posT_CODE1 = cep;

        const cnpjcpf = String(form.taxnumxl);
        const numStr = cnpjcpf.replace(/[^0-9]/g, '');
        form.taxnumxl = numStr;

        // const ie = String(form.taxnumxL_EST);
        // const ieStr = ie.replace(/[^0-9]/g, '');
        // form.taxnumxL_EST = ieStr;

        // const mun = String(form.taxnumxL_MUNIP);
        // const munStr = mun.replace(/[^0-9]/g, '');
        // form.taxnumxL_MUNIP = munStr;

        this.registerService.save(form)
            .subscribe(retorno => {

                if(retorno.solcad == "200"){
                    this.toastrService.success('Sucesso!', 'Obrigado por realizar o seu cadastro, assim que que confirmarmos ele aqui, você receberá um email com os dados de acesso!', { duration: 10000 });
                    setTimeout(() => this.router.navigate(['/auth/login']), 10000);
                }else{
                    this.toastrService.danger('Erro!', retorno.message, { duration: 5000 });
                }
            }, error => {

            });
    }

    public async buscarEndereco(cep: string) {

        const cepFormat = cep.replace('.', '');

        const enderecoCompleto = await this.utilsService.obterLogradouro(cepFormat)
            .toPromise();

        this.registerForm.patchValue({
            street: enderecoCompleto.logradouro,
            citY1: enderecoCompleto.localidade,
            region: enderecoCompleto.uf,
            citY2: enderecoCompleto.bairro,
        });

    }

    onlynumber(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        //var regex = /^[0-9.,]+$/;
        var regex = /^[0-9.]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
}